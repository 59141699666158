.language-switch {
  span.label {
    span.active-lang {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: var(--black);
        left: 50%;
        transform: translateX(-50%);
        bottom: -6px;
        border-radius: 50%;
      }
    }
  }
}

#fullscreen-menu {
  .language-switch {
    gap: rem-calc(20);
    
    a {
      border: none;
    }
  }
}