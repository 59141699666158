section.paragraph.paragraph-hero-text {
  .hero.text {
    p {
      @include body(m);

      a {
        @include link(hero);
      }
    }
  }
}
