/* Reset and normalize */
@import "~css-reset-and-normalize/scss/reset-and-normalize.scss";

/* recaptcha */
@import "../../node_modules/react-client-captcha/dist/index.css";

/* react loading spinner */
@import "../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/*7 React Lightbox */
@import "../../node_modules/lightbox-react/style.css";

/* Carbon */
@import "~carbon-components/scss/components/select/select";
@import "~carbon-components/scss/components/search/search";
@import "~carbon-components/scss/components/button/button";
@import "~carbon-components/scss/components/notification/inline-notification";

/* react-accessible-accordion */
//@import '~react-accessible-accordion/dist/fancy-example.css';

@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-up(xs) {
  :root {
    --container-margin: calc(100vw * 0.05 / 2);
  }
}

@include media-breakpoint-up(sm) {
  :root {
    --container-margin: calc(100vw * 0.05 / 2);
  }
}

@include media-breakpoint-up(md) {
  :root {
    --container-margin: calc(100vw * 0.2 / 2);
  }
}

@media (min-width: 2000) {
  :root {
    --container-margin: calc(2000px * 0.2 / 2);
  }
}

@import "themes";
@import "_mixins";
@import "icons";
@import "typo";
@import "forms";
@import "controls";

// Components.
@import "footer";
@import "header";
@import "eforkbot";
@import "components/fullscreen-menu";
@import "components/paragraph-hero-text";
@import "components/paragraph-click-suggestions";
@import "components/paragraph-teaser-list";
@import "components/paragraph-teaser-image";
@import "components/paragraph-sectionheading";
@import "components/paragraph-text";
@import "components/paragraph-number-fact";
@import "components/paragraph-teaser-slider";
@import "components/paragraph-newsletteranmeldung";
@import "components/paragraph-akkordion-text";
@import "components/paragraph-dossier";
@import "components/paragraph_page-title";
@import "components/paragraph_call-to-action";
@import "components/paragraph-extended-teaser-overview";
@import "components/paragraph-calendar-information";
@import "components/paragraph-download";
@import "components/paragraph-zitat";
@import "components/paragraph-highlighted-information";
@import "components/pager-full-page";
@import "components/paragraph-download-schublade";
@import "components/paragraph-timeline";
@import "components/paragraph-image-image-slider";
@import "components/paragraph-history";
@import "components/paragraph-strukturierte-liste";
@import "components/paragraph-kalender-einzel-doppel";
@import "components/paragraph-kalender-abschnitt";
@import "components/paragraph-kalender-monat";
@import "components/paragraph-media";
@import "components/paragraph-audio";
@import "components/paragraph-logos-partners";
@import "components/content-cloud/paragraph-content-cloud";
@import "components/content-cloud/detail-overlay";
@import "components/paragraph-text-and-speech";
@import "components/paragraph-intro";
@import "components/paragraph-gallery";
@import "components/paragraph-simple-teaser-list";
@import "components/paragraph-webform";
@import "webform";
@import "components/language-switch";
@import "components/commerce";
@import "components/cookie-banner";

/* Slick SCSS */
$slick-font-path: "/font/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick-track {
  background-color: var(--grey-20);
  display: flex;
  align-items: center;
}

.slick-prev,
.slick-next {
  z-index: 1 !important;
  width: rem-calc(50);
  height: rem-calc(50);
  bottom: 0;
  top: auto;
  transform: initial;
  background-color: var(--theme-1);

  &:hover {
    background-color: var(--theme-1-active);
  }

  &::before {
    content: none;
  }

  &:focus,
  &:focus-visible {
    outline: rem-calc(1) solid black;
    background-color: var(--theme-1);
  }

  svg {
    width: rem-calc(25);
    height: rem-calc(25);
  }
}

.slick-prev {
  left: 0;
  border-right: 1px solid var(--black);

  @include theme(dark) {
    border-right: 1px solid var(--white);
    .icon {
      filter: none;
    }
  }
}

.slick-next {
  left: rem-calc(50);

  @include theme(dark) {
    .icon {
      filter: none;
    }
  }
}

.slick-dots {
  bottom: 0;
  height: rem-calc(50);

  li {
    margin: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        top: unset;
        left: unset;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: var(--theme-1-active);
      }
    }
  }
}

body {
  background-color: var(--theme-2);
  margin: 0;
}

.main-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 0;
  margin: 0;

  main {
    background-color: var(--white);
    width: 100%;
    min-height: 100%;
    flex: 1;

    @include media-breakpoint-up(xxl) {
      @include theme("dark") {
        border-left: 1px solid var(--black);
        border-right: 1px solid var(--black);
      }
    }
  }
}

.content-padding {
  @each $key, $value in $container-max-widths {
    @include media-breakpoint-up($key) {
      max-width: $value;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@each $key, $value in $container-max-widths {
  .reset-content-padding-#{$key} {
    @include media-breakpoint-up($key) {
      max-width: inherit;
    }
  }
}

// Nodes.
article.node-teaser {
  display: flex;

  .flex-wrap {
    display: block;
    width: 100%;
  }

  .top-line {
    display: block;
    @include body(xs);
  }

  a {
    color: var(--black);
    text-decoration: none;

    &:hover {
      text-decoration: none;

      h3 {
        text-decoration: underline;
      }
    }
  }
}

@import "nodes/node-article";
@import "nodes/node-projekt";
@import "nodes/node-landingpage";
@import "nodes/node-searchpage";
@import "nodes/node-person";
@import "nodes/node-event";
@import "nodes/node-news";

.article-content-section {
  .img-wrapper {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

img {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.site-logo-button {
  @include header-link();
}

html,
body {
  font-size: inherit;
}

#app {
  //overflow-x: hidden;
  > * {
    @include body(s);
    margin-bottom: 0;
  }
}

.paragraph {
  padding: $paragraph-section-padding-mobile;
  @include media-breakpoint-up(md) {
    padding: $paragraph-section-padding;
  }
}

article.node-teaser {
  position: relative;
}

.img-wrapper {
  position: relative;
}

.loading-indicator-wrap {
  min-height: 80svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-reverse {
  justify-content: space-between;
}
