section.paragraph.paragraph-download {
  padding: 0;

  a {
    text-decoration: none;
    display: block;
    transition: 0.3s all;
    padding: rem-calc(32) 0;

    &:hover {
      background: var(--theme-2);
    }

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;

      .text {
        margin-right: rem-calc(20);
        
        .subline {
          margin-bottom: rem-calc(8);
        }
        
        p {
          margin-bottom: 0;
        }
      }

      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: rem-calc(48);
        width: rem-calc(48);
        height: rem-calc(48);
        border: 1px solid var(--black);
        border-radius: rem-calc(24);
      }
    }
  }

  &:first-child {
    border-top: 1px solid var(--grey-60);
  }

  border-bottom: 1px solid var(--grey-60);
}
