section.paragraph.paragraph-gallery {
  .slider-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.75);
    height: 100vh;
    z-index: 100;
    cursor: zoom-out;

    .close-overlay {
      filter: invert(1);
      position: absolute;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      cursor: pointer;
    }

    .slick-track {
      background-color: transparent;
    }

    .slider-wrapper {
      max-width: 100%;
      max-height: 100%;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(sm) {
        max-width: 80%;
      }
    }

    .slick-slider {
      position: static;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .slick-prev,
      .slick-next {
        top: 50%;
        transform: translateY(-50%);
      }

      .slick-prev {
        left: 0;
        right: auto;
      }

      .slick-next {
        left: auto;
        right: 0;
      }
    }

    .slide > img {
      max-height: 80vh;
      object-fit: cover;
      cursor: initial;
    }

    .caption-wrapper {
      color: var(--white);
      display: flex;
      justify-content: space-between;
      margin-top: rem-calc(10);
      cursor: initial;
    }
  }

  .gallery-item {
    position: relative;
    cursor: zoom-in;
    margin-bottom: rem-calc(25);

    .download-button {
      color: black;
      background-color: var(--theme-1);
      width: 50px;
      height: 50px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
    }
  }
}
