section.paragraph-teaser-list {
  padding-bottom: 0;

  .teaser-list-head {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h2 {
    margin-bottom: rem-calc(60);
  }
  .more {
    margin-top: rem-calc(30);
    @include media-breakpoint-up(md) {
      margin-top: rem-calc(60);
    }
  }

  .container > .row > .col-16 {
    padding: 0;
  }
}

section.paragraph-teaser-list {
  .slick-track {
    align-items: stretch;
  }

  .slick-slide {
    align-self: stretch;
    height: inherit;

    > div {
      height: 100%;

      > .col-third {
        height: 100%;
      }
    }
  }

  .headline-wrapper {
    h2 {
      margin-bottom: rem-calc(15);
    }

    @include media-breakpoint-down(md) {
      padding: 100px 0 0 0;
    }
  }

  &.layout-vertical {
    border-bottom: var(--grey-30);

    .headline-wrapper {
      margin-top: rem-calc(50);
      margin-bottom: rem-calc(50);
    }
  }

  &.layout-horizontal {
    padding: 0;

    .headline-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid var(--grey-60);
    }

    .teaser-wrapper {
      padding: 0;
    }
  }
}

.paragraph-teaser-list-veranstaltung {
  article.node {
    margin-bottom: 2rem;
  }
}
