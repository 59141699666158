article.node-searchpage {
  min-height: 60svh;

  .search-results-amount {
    margin-top: rem-calc(30);
  }

  .searchbox-wrapper {
    .searchbox-headline {
      margin-bottom: rem-calc(10);
    }
  }

  .search-result-teaser {
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(50);

    .result-body {
      margin: rem-calc(8) 0;
      p {
        margin: 0;
      }
    }
  }

  .pagination {
    .pagination-button {
      @include body(s);
      min-width: 0;
      margin-right: rem-calc(8);
      width: rem-calc(70);
      height: rem-calc(70);
      display: flex;
      align-items: center;
      justify-content: center;

      &.arrow {
        background-color: initial;
      }

      &.active {
        outline: 4px solid var(--black);
        outline-offset: -4px;
        background-color: var(--theme-1);
      }
      
      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
