/**
 * Teaser Views modes.
 */
article.node.node-teaser.teaser-projekt {
  min-height: rem-calc(480);
  padding: rem-calc(15);
  background-size: cover;
  background-position: center center;

  h3 {
    @include body(m);
    margin-bottom: rem-calc(15);
  }

  .tags {
    display: flex;

    span {
      color: var(--grey-30);
      @include body(xs);
      background: white;
      padding: rem-calc(4) rem-calc(8);
      border-radius: rem-calc(12);
      margin-right: rem-calc(10);
    }
  }
}
