.paragraph {
  --form-text-color: var(--black);
  --form-input-bg-color: var(--white);
  --form-input-text-color: var(--black);
  --form-input-border: 1px solid var(--grey-40);

  &.theme-1,
  &.theme-2,
  &.theme-3 {
    --form-text-color: var(--white);
    --form-input-bg-color: var(--white);
    --form-input-text-color: var(--black);
    --form-input-border: none;
  }
}

form {
  .form-check {
    padding-left: 2rem !important;
    .form-check-input {
      margin-top: 0;
      border-radius: 0;
      width: rem-calc(20);
      height: rem-calc(20);
      margin-left: -2rem;
      border: var(--form-input-border);
      accent-color: var(--black);
      cursor: pointer;

      &:hover {
        background-color: var(--theme-1);
      }

      &:checked {
        background-color: var(--theme-1-active);
        background-size: rem-calc(12);
        --bxform-check-bg-image: url("/icon-cross.svg");
      }
    }
    label {
      @include body(xs);
    }

    .error-message {
      order: 3;
      margin-top: rem-calc(16);
    }
  }
  label {
    color: var(--form-text-color);
    @include body(xs);
  }
  input[type="text"],
  input[type="email"],
  select {
    background: var(--form-input-bg-color);
    height: rem-calc(40);
    @include body(xs);
    border-radius: unset;
    border: var(--form-input-border) !important;
  }
  /* input[type="checkbox"] {
    margin-right: rem-calc(10);
  } */
  .form-error {
    display: block;
    margin-bottom: rem-calc(-10);
  }
  button[type="submit"] {
    margin-top: rem-calc(50);
  }
}

.alert {
  color: var(--black) !important;
  border-radius: 0 !important;
}
