@mixin flex-center() {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin after-triangle($color) {
  content: "";

  width: 0;
  height: 0;
  border-left: rem-calc(20) solid transparent;
  border-right: rem-calc(20) solid transparent;
  position: absolute;
  bottom: rem-calc(-20);
  left: calc(50% - #{rem-calc(-20)});
  border-top: rem-calc(35) solid $color;
}

@mixin heading-line($width: 80%) {
  display: table;
  position: relative;
  &:before {
    content: "";
    width: $width;
    height: rem-calc(1);
    background: $black-3;
    bottom: rem-calc(17);
    left: 105%;
    display: block;
    position: absolute;
  }
}
/*
@mixin button($border-color, $background-color, $box-shadow-color: $black-4) {
  background: $background-color;
  padding: rem-calc(15) rem-calc(25) rem-calc(10) rem-calc(25);
  min-width: rem-calc(220);
  text-align: center;
  border: rem-calc(2) solid $border-color;
  border-top-left-radius: rem-calc(5);
  border-top-right-radius: rem-calc(5);
  position: relative;
  cursor: pointer;
  color: $border-color;
  box-shadow: 0 rem-calc(10) 0 rem-calc(-5) $box-shadow-color;
  transition: all 250ms ease;
  &:hover,
  &:active {
    transform: translateY(rem-calc(5));
    text-decoration: none;
    box-shadow: 0 rem-calc(5) 0 rem-calc(-5) $box-shadow-color;
  }
  &:hover:after {
    display: none;
  }
}
*/

@mixin container-border() {
  border-left: rem-calc(1) solid $topics-border-color;
  border-right: rem-calc(1) solid $topics-border-color;
}

@mixin break-grid() {
  width: calc(#{$grid-padding} * 2 + 100% + #{$grid-gutter-width});
  margin-left: calc(-#{$grid-padding} + #{$grid-gutter-width});
}

@mixin border-bottom-full-width() {
  &:after {
    content: "";
    width: 100vw;
    height: rem-calc(1);
    background-color: $topics-border-color;
  }
}

/* Theme Mixin */
@mixin theme($theme) {
  @at-root html[data-theme="#{$theme}"] & {
    @content;
  }
}

/* Transition Mixin */
@mixin transition($property) {
  transition: $transition-duration $property $transition-timing-function;
}
