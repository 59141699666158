article.article {
  .article-header {
    .content-type {
      margin-bottom: rem-calc(16);
    }
  }

  .content-wrap {
    .paragraph:first-child {
      margin: 0;
      padding-top: 0;
    }
  }
}
