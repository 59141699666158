section.paragraph.paragraph-bilder-slider {
  .slider-wrapper {
    position: relative;

    .media-image {
      padding: 0;
    }

    .controls {
      .slick-prev,
      .slick-next {
        z-index: 1 !important;
        width: rem-calc(50);
        height: rem-calc(50);
        bottom: 0;
        top: auto;
        transform: initial;
        background-color: var(--theme-1);

        &:hover {
          background-color: var(--theme-1-active);
        }

        &::before {
          content: none;
        }

        &:focus,
        &:focus-visible {
          outline: rem-calc(1) solid var(--black);
          background-color: var(--theme-1);
        }

        svg {
          width: rem-calc(25);
          height: rem-calc(25);
        }
      }
    }
  }
}
