section.paragraph.paragraph-timeline {
  .timeline {
    &:after {
      content: "";
      background-color: var(--grey-10);
      position: absolute;
      top: 0;
      bottom: 0;
      width: rem-calc(2);
      left: calc(50% - #{rem-calc(-2)});
    }
    .top,
    .bottom {
      background: white;
      text-align: center;
      color: var(--grey-10);
      @include body(xs);
      z-index: 1;
    }
    .bottom {
      padding-top: rem-calc(5);
      margin-top: auto;
    }
    .top {
      padding-bottom: rem-calc(5);
    }
  }
  .timeline-column-1 {
    @include media-breakpoint-up(lg) {
      padding-top: rem-calc(120);
    }
  }
  .timeline-filter {
    padding: rem-calc(50) 0 rem-calc(75) 0;
    .filter-cloud {
      padding: 0;
    }
    .list-switch {
      svg {
        margin-left: rem-calc(15);
        cursor: pointer;
        &:hover,
        &.active {
          fill: var(--theme-1-active);
        }
      }
    }
  }
  article {
    margin-bottom: rem-calc(65);
  }
}
