.paragraph.webform-paragraph {
  padding: rem-calc(96) 0;
}

.paragraph.webform-paragraph .webform-container {
  position: relative;

  .webform-composite-address {
    .street-address {
      .form-group:first-child {
        margin-bottom: 0;
      }
    }
  }

  .alert {
    background-color: transparent;
  }

  .webform-details {
    padding: 0 1em 0 1em;

    &[open] {
      padding-bottom: 1em;
    }

    summary {
      padding: 1em 0;
      font-size: 1.25em;
    }

    //border: rem-calc(1) solid var(--grey-10);
  }

  .webform-fieldset {
    padding: rem-calc(16);
    //border: rem-calc(1) solid var(--grey-10);

    legend {
      display: inline-block;
      width: auto;
      padding: 0 0.5em;
    }
  }

  .webform-flexbox {
    margin-bottom: 0 !important;

    .flexbox-wrap {
      display: flex;
      gap: 1em;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      > * {
        min-width: 0;
        flex: 1;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .form-group.form-check + .form-group.form-check {
    margin-top: -1.25em;
  }

  .file-input-group {
    .files-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      .files-list-item {
        gap: 1rem;
        align-items: center;
        background-color: #0003;
        justify-content: space-between;

        span {
          padding: 0.5rem;
        }

        .file-delete {
          color: var(--black);
          padding: 0.75rem;
          min-width: 1.5rem;
        }
      }
    }
  }

  h2 {
    @include body(l);
    margin-bottom: calc(#{$whitespace} / 2);
  }

  h3 {
    @include body(m);
    margin-bottom: calc(#{$whitespace} / 2);
  }

  p:last-child {
    margin-bottom: calc(#{$whitespace} * 2);
  }

  @include body(xs);

  label {
    .required {
      color: red;
    }
  }

  .error-message {
    color: red;
  }

  .loading-indicator-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(50, 50, 50, 0.15);
  }

  form {
    .form-description {
      font-size: 100%;
      color: var(--form-text-color);
    }

    .form-check {
      display: flex;
      flex-flow: column;
      margin-right: rem-calc(16);
      justify-content: center;
      padding-bottom: rem-calc(10);
      @include media-breakpoint-down(md) {
        margin-bottom: rem-calc(8);
      }

      input[type="checkbox"]:before {
        position: relative;
      }

      label {
        display: inline-block !important;
        margin-top: rem-calc(3) !important;
        display: none;
      }

      small {
        @include body(xs);
        text-transform: initial;
        color: var(--form-text-color) !important;

        a {
          @include link();
          margin: 0 rem-calc(3);
          line-height: 1.2;
        }
      }
    }

    small {
      color: var(--form-text-color) !important;
    }

    .markup {
      p:last-child {
        margin-bottom: 1rem;
      }
    }

    .form-group.custom-composite {
      label {
        h3 {
          margin-bottom: 0;
        }
      }

      label + .form-row {
        margin-top: 1rem;
      }

      .form-description {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }

  input[type="file"]::file-selector-button {
    background-color: #ccc;
  }

  .markup.hidden {
    display: none;
  }

  .form-group {
    &.hidden {
      display: none;
    }

    margin-bottom: rem-calc(32);

    .error-message {
      @include body(xxs);
      margin-top: rem-calc(4);
      display: block;
    }
  }
}
