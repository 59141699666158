section.paragraph.paragraph-newsletter {
  position: relative;
  background-color: var(--theme-3);
  padding: rem-calc(64) 0;

  p,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    color: var(--white) !important;
  }

  .form-head {
    margin-bottom: rem-calc(32);
  }

  .form-group:not(.form-check) label {
    @include body(xxs);
    margin-bottom: rem-calc(5);
  }

  .form-group {
    margin-bottom: rem-calc(32);
  }

  .form-check {
    display: block;
  }

  button {
    @include button;
    margin-top: 0;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .form-error {
    margin-top: .25rem;
  }

  .text {
    p {
      margin-top: rem-calc(-5);
    }

    a {
      color: var(--white);

      &:hover {
        color: var(--black);
      }
    }
  }
}
