section.paragraph.paragraph-click-suggestions {
  h2 {
    @include body(m);
    margin-bottom: rem-calc(60);
  }
  ul {
    margin: 0;
    li {
      padding: 0 0 rem-calc(20) 0;
      display: flex;
      align-items: center;
      svg {
        width: rem-calc(25);
        margin-right: rem-calc(5);
        min-width: rem-calc(25);
      }
      a {
        color: var(--black);
        @include body(m);
      }
    }
  }
}
