@mixin button {
  @include body(xxs);
  background-color: var(--button-bg);
  color: var(--button-color);
  padding: rem-calc(20) rem-calc(25);
  min-width: rem-calc(170);
  border-radius: 0;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--theme-1-active);
  }
}

.btn.btn-secondary {
  color: var(--theme-1-active);
  border: rem-calc(1) solid var(--theme-1-active);
  background: white;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: var(--theme-1-active);
  }
}

.btn,
.btn.btn-primary {
  @include button;
}

a.line-link {
  position: relative;
  padding-left: 45px !important;
  @include link(small);
  margin-left: 0;

  &:before {
    content: "";
    background-color: var(--black);
    height: 1px;
    width: 40px;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.search {
  display: flex;
  width: 100%;

  input {
    border: 1px solid var(--grey-30);
    width: 100%;
    @include body(xxs);
    padding: rem-calc(15) rem-calc(15);
    @include media-breakpoint-up(lg) {
      padding: rem-calc(20) rem-calc(25);
    }
    background-color: var(--white);
    color: var(--black);

    &:focus,
    &:focus-visible {
      outline: 4px solid var(--theme-1);
      outline-offset: -4px;
    }
  }

  button {
    background-color: var(--theme-1);
    border: none;
    padding: rem-calc(15) rem-calc(15);
    @include media-breakpoint-up(lg) {
      padding: rem-calc(20) rem-calc(25);
    }
    @include body(xxs);
    @include theme(dark) {
      color: var(--white);
    }

    &:hover {
      background-color: var(--theme-1-active);
    }
  }
}
