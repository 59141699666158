@mixin header-link {
  color: var(--black);
  @include body(xxs);
  padding: rem-calc(16) rem-calc(16);
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover span.label,
  &.active span.label,
  &.open span.label {
    border-bottom: 4px solid var(--theme-1-active);
    margin-bottom: -4px;
  }

  &.has-children {
    &:after {
      content: "";
      padding: rem-calc(6);
      margin-left: rem-calc(6);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      background-image: url("/icon-dropdown-arrow.svg");
      display: inline-flex;
      transition: 0.3s transform;
      transform: rotate(0);

      @include theme(dark) {
        filter: invert(1);
      }
    }

    &.open:after {
      transform: rotate(180deg);
    }
  }
}

$header-main-height: rem-calc(72);
$header-meta-height: rem-calc(50);
$header-main-height-mobile: rem-calc(56);
$header-meta-height-mobile: rem-calc(40);

header#site-header {
  --header-meta-bg: var(--grey-10);
  --header-main-bg: var(--white);

  @include theme(dark) {
    --header-meta-bg: var(--grey-30);
    --header-main-bg: var(--grey-10);
  }

  /* General Styling */
  display: flex;
  flex-direction: column;
  background-color: var(--header-main-bg);

  + main {
    padding-top: rem-calc(56 + 40);

    @include media-breakpoint-up(lg) {
      padding-top: rem-calc(72 + 50);
    }
  }

  .logo-big {
    position: fixed;
    top: 100svh;
    transform: translateY(-100%);
    width: rem-calc(276);
    right: 0;
    opacity: 1;
    transition: 0.3s opacity;

    @include media-breakpoint-down(md) {
      width: rem-calc(200);
    }
  }

  /*   &.scrolled .logo-big {
    opacity: 0;
  } */

  .header-main {
    display: flex;
    align-items: center;
    background-color: var(--header-main-bg);
    min-height: $header-main-height-mobile;
    justify-content: space-between;
    z-index: 11;

    @include media-breakpoint-up(lg) {
      min-height: $header-main-height;
    }

    .logo-wrapper {
      width: $header-main-height;
      height: $header-main-height;

      @include media-breakpoint-down(lg) {
        width: fit-content;
        height: fit-content;
        margin-right: auto;
      }
    }

    nav.main-menu-desktop {
      display: flex;
      align-items: center;
      gap: 20px;

      .newsletter-btn,
      .sammlungsaufruf-btn {
        padding: rem-calc(12) rem-calc(16);
      }


      @include media-breakpoint-down(lg) {
        display: none;
      }

      .level-1-wrapper {
        > ul {
          margin-bottom: 0;
          display: flex;

          > li {
            position: relative;
            a {
              cursor: pointer;
              position: relative;
              @include header-link();
            }
          }
        }
      }

      .level-2-wrapper {
        position: absolute;
        display: none;
        box-shadow: 0 3px 5px #00000022;
        min-width: rem-calc(300);

        &.open {
          display: block;
        }

        > ul {
          display: flex;
          flex-direction: column;
          background-color: var(--header-main-bg);
          padding: rem-calc(25) 0;

          li a {
            display: block;
            padding: rem-calc(8) rem-calc(16);
          }
        }
      }
    }

    .icon-search {
      height: $header-main-height-mobile;
      width: $header-main-height-mobile;
      padding: 0.75rem;
      background-origin: content-box;

      @include media-breakpoint-up(lg) {
        margin-right: rem-calc(25);
        height: $header-main-height;
        width: $header-main-height;
        padding: rem-calc(20);
      }
    }
  }

  .searchbox {
    display: flex;
    justify-content: center;

    .search {
      max-width: 800px;
      margin: rem-calc(32);
    }
  }

  /* Scroll Behaviour */
  position: fixed;
  width: 100%;
  top: -#{$header-meta-height}; /* accessibility menu not visible by default */
  @include media-breakpoint-down(md) {
    top: -#{$header-meta-height-mobile}; /* accessibility menu not visible by default */
  }
  transition: 0.6s all;
  z-index: 10;

  @include media-breakpoint-up(lg) {
    .header-main {
      position: relative;
    }

    .header-main .logo-wrapper img,
    .header-main .main-menu-desktop {
      position: relative;
      transition: 0.3s left;
      left: 0;
    }
  }

  /* Menu disappears completely on scolling down */
  &.scrolling-down {
    top: rem-calc(-(72 + 50));
    box-shadow: initial;

    .header-main {
      left: 0 !important;
    }
  }

  /* Menu appears completely at top */
  &.is-top {
    top: 0;

    /**/
    @include media-breakpoint-up(lg) {
      .header-main .logo-wrapper img,
      .header-main .main-menu-desktop {
        left: -#{$header-main-height};
      }
    }
  }

  &.scrolled {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }

  /* Hamburger Menu */
  #toggle-fullscreen-menu {
    display: inline-block;
    cursor: pointer;
    z-index: 20;
    padding: 12px;
    background-color: var(--theme-1);

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:focus,
    &:focus-visible {
      outline: rem-calc(1) solid black;
    }

    [class*="line"] {
      transition: 0.3s all;
      transform-origin: center;
    }

    &.active {
      .line-1 {
        transform: translateY(-10px);
        opacity: 0;
      }
      .line-2 {
        transform: rotate(45deg);
      }
      .line-3 {
        transform: rotate(-45deg);
      }
      .line-4 {
        transform: translateY(10px);
        opacity: 0;
      }
    }
  }
}

.sammlungsaufruf-btn {
  background-color: #EC6FA7 !important;
  color: #ffffff !important;

  &:hover {
    background-color: #e03481 !important;
  }
}

.visitor-back-button {
  position: absolute;
  background-color: var(--theme-1);
}

.header-meta {
  justify-content: space-between;
  align-items: stretch;
  padding: 0 rem-calc(25);
  background-color: var(--header-meta-bg);
  display: flex;
  height: $header-meta-height-mobile;
  z-index: 12;

  .social-media {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      gap: rem-calc(24);
      margin-left: rem-calc(20);

      img {
        width: rem-calc(20);
        height: rem-calc(20);
        opacity: 0.5;
      }

      a:hover img {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    height: $header-meta-height;
  }

  nav {
    display: flex;

    @include media-breakpoint-down(lg) {
      width: 100%;
      justify-content: space-between;
    }

    .link {
      display: flex;
      color: var(--black);
      text-decoration: none;
      align-items: center;
      padding: rem-calc(10) rem-calc(15);
      cursor: pointer;

      .icon-wrapper {
        margin-right: rem-calc(10);
        display: flex;
      }

      .label {
        @include body(xxs);

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }

    > .language-switch {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex !important;
      }
    }
  }

  .header-meta-sub-wrapper {
    position: absolute;
    right: 0;
    top: $header-meta-height;
    z-index: 10;
  }

  .header-meta-sub-menu.menu-font-size {
    position: absolute;
    right: 0;
    z-index: 11;
    background-color: var(--white);
    padding: rem-calc(25) rem-calc(50);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);

    .inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .sub-menu-label {
        border-bottom: 1px solid var(--border-color-1);
        display: block;
        text-align: center;
        width: 100%;
        padding: rem-calc(5) rem-calc(25) rem-calc(25) rem-calc(25);
      }
    }

    .font-size-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: rem-calc(25);

      > * {
        padding: 5px;
      }

      .font-size-from {
        font-size: 1rem;
      }

      .font-size-to {
        font-size: 1.6rem;
      }
    }
  }

  .slider-wrapper {
    display: flex;
    justify-content: center;
  }

  input[type="range"] {
    width: 200px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      background: var(--border-color-1);
      height: 1px;
    }

    &::-moz-range-track {
      background: var(--border-color-1);
      height: 1px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      margin-top: -12px; /* Centers thumb on the track */
      background-color: var(--theme-3);
      height: 24px;
      width: 24px;
      border-radius: 12px;
    }
  }
}

.visitor-menu {
  background-color: var(--white);
  padding: rem-calc(25) rem-calc(50);
  min-width: rem-calc(240);

  @include media-breakpoint-up(md) {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }

  .language-switch {
    display: flex;
    padding-bottom: rem-calc(20);
    margin-bottom: rem-calc(25);
    border-bottom: 1px solid var(--grey-30);
    justify-content: center;

    a {
      padding: rem-calc(5) rem-calc(25);
      text-decoration: none;
      opacity: 0.5;

      &.active,
      &:hover {
        opacity: 1;
      }

      &:not(:last-of-type) {
        border-right: 1px solid var(--grey-30);
      }
    }
  }

  .visitor-info {
    display: flex;
    align-items: center;
    flex-direction: column;

    .label {
      margin-bottom: rem-calc(20);
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;

      a {
        @include link;
      }
    }
  }
}
