section.paragraph.paragraph-text {
  margin: rem-calc(96) 0 rem-calc(32) 0;

  &.background-color {
    .text {
      padding: rem-calc(30) rem-calc(40);
      background: var(--white);
    }
  }
}

.paragraph-sectionheading + .paragraph-text {
  margin-top: 0 !important;
}

.article-content {
  section.paragraph.paragraph-text {
    margin-top: 0;

    [class^="col"] {
      width: 87.5%; // col-14 width
    }

    @include media-breakpoint-up(lg) {
      [class^="col"] {
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
  }
}
