section.paragraph.paragraph-strukturierte-liste {
  h3 {
    @include headline(m);
    padding-bottom: 2.5rem;
  }

  .content {
    @include body(xxs);

    .list-item {
      padding-bottom: 1.5em;
    }

    h3 {
      @include body(xs);
      padding-bottom: 1.5rem;
    }

    table {
      width: 100%;

      td {
        padding-bottom: rem-calc(15);
      }

      tr td:nth-child(1) {
        padding-right: rem-calc(15);
      }

      @include media-breakpoint-up(lg) {
        @include make-col-offset(1);
      }
    }
  }

  .hint {
    background-color: var(--white);
    color: black;

    a {
      // TODO: Before svg color.
      color: black;
    }
  }
}
