section.cookie-banner-wrapper {
  font-size: 14px;

  --font-size-body: 1rem;
  --font-size-h1: 2rem;
  --font-size-h2: 1.5rem;
  --font-size-h3: 1.25rem;

  position: fixed;
  z-index: 200;
  width: 100vw;
  width: 100svw;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;

  .cookie-banner {
    position: absolute;
    width: 90%;
    left: 5%;
    top: 5%;
    padding: rem-calc(25);
    background-color: var(--white);
    max-height: 90%;
    overflow: auto;

    @include media-breakpoint-up(sm) {
      width: 70%;
      left: 15%;
      top: 5%;
    }

    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
      top: rem-calc(100);
      max-width: rem-calc(500);
    }

    &-form {
      label {
        gap: rem-calc(10);
      }
    }
  }

  .action-wrapper {
    gap: rem-calc(10);
  }

  .btn {
    margin-top: rem-calc(10);
    width: 100%;
  }

  label {
    margin-bottom: rem-calc(15);
  }

  .text {
    h2 {
      font-size: var(--font-size-h1) !important;
      margin-bottom: rem-calc(10);
      background-color: transparent;
      padding: 0;
    }

    h3, legend {
      font-size: var(--font-size-h2);
      margin-bottom: rem-calc(10);
      font-weight: 500;
    }

    h4 {
      font-size: var(--font-size-h3);
      margin-bottom: rem-calc(10);
    }

    p {
      font-size: var(--font-size-body);
      margin-bottom: rem-calc(10);
      line-height: 1.4;
    }
  }
}

section.paragraph.paragraph-cookie-consent {
  padding-top: 0;
  margin-bottom: rem-calc(32);
}