section.paragraph.paragraph-highlighted-information {
  padding-top: rem-calc(125);

  .outer-bounds {
    margin-top: rem-calc(65);
  }
  .title {
    @include headline(m);
    font-weight: 600;
  }

  .flex-wrapper {
    display: flex;
  }

  .text-wrapper,
  .icon-wrapper {
    padding-bottom: rem-calc(50);
  }

  .icon-wrapper {
    min-width: 5rem;
    width: 5rem;
    border-radius: 50%;
    height: 5rem;
    object-fit: contain;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    img {
      width: 70%;
      height: 70%;
    }
  }

  .text-wrapper {
    margin-left: rem-calc(25);
    flex: 1 1 auto;
  }

  .discription {
    @include body(xs);
    line-height: 150%;
  }
  .link {
    @include link;
    padding-left: rem-calc(30);
    position: relative;
    &:before {
      content: "";
      width: rem-calc(25);
      left: 0;
      top: 50%;
      height: 1px;
      background-color: var(--black);
      position: absolute;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: all 0.2s ease;
      -webkit-backface-visibility: hidden;
    }
  }

  &.theme-1 {
    --text-color: var(--white);
    --background-color: var(--theme-3);

    @include theme(dark) {
      --text-color: var(--black);
      --background-color: var(--grey-20);
    }

    background-color: var(--background-color);

    * {
      color: var(--text-color);
    }

    p a:hover {
      color: var(--text-color);
    }

    .line-link {
      color: var(--text-color);
      &:hover::before {
        background-color: var(--black);
      }

      &:before {
        background-color: var(--text-color);
        transition: 0.3s all;

        &:hover {
          color: var(--black);
        }
      }
      &:hover {
        color: var(--black);
      }
    }
  }
}
