.paragraph-text-and-speech {
  position: relative;
  .text-container {
    cursor: pointer;
    .text-wrapper {
      color: var(--theme-1);
      @include body(xl);
      font-weight: 700;
      .initial-text {
        color: black;
        @include body(xl);
        font-weight: 700;
      }
    }
    .source {
      color: var(--random-text-color);
      font-family: Raleway, sans-serif;
      font-weight: 700;
      line-height: 1.3;
      margin-top: rem-calc(10);
      font-size: rem-calc(24);
      @media all and (max-width: rem-calc(1024)) {
        font-size: rem-calc(24);
      }
    }
    cursor: none;
    /*&.hover-cursor{
      cursor: url("/LOS-zuhoeren.png"), auto;
      cursor: url("/tas-zuhoeren.svg"), auto;
      cursor: -webkit-image-set(url("/LOS-zuhoeren.png") 1x, url("/LOS-zuhoeren2x.png") 2x), auto;
      &.playing{
        cursor: url("/LOS-stop.png"), auto;
        cursor: url("/tas-stop.svg"), auto;
        cursor: -webkit-image-set(url("/LOS-stop.png") 1x, url("/LOS-stop2x.png") 2x), auto;
      }
    }*/
  }
}

.custom-cursor {
  pointer-events: none;
  z-index: 1000;
  position: relative;
  img {
    position: fixed;
    height: rem-calc(44);
    width: auto;
    pointer-events: none;
    //opacity: .8;
  }
}
