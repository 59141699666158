footer#pageFooter {
  padding: rem-calc(35) 0 rem-calc(65) 0;
  background-color: var(--grey-10);

  hr {
    opacity: 1;
    border-top: 1px solid var(--border-color-2);
  }

  .container {
    padding: 0 rem-calc(30);
  }

  hr.footer-line {
    margin-top: 0;
    margin-bottom: rem-calc(40);
  }

  .footer-menu {
    @include media-breakpoint-up(lg) {
      order: 2;
    }

    .footer-sitemap {
      margin-bottom: rem-calc(30);

      nav {
        columns: 2;
      }

      .link-wrapper {
        display: flex;
        padding: rem-calc(10) 0;

        a {
          @include link(small);
        }
      }
    }

    .footer-social-media {
      padding-bottom: rem-calc(60);
      display: flex;

      @include theme(dark) {
        svg {
          filter: invert(1);
        }
      }

      a {
        width: rem-calc(30);
        height: rem-calc(30);
        margin-right: rem-calc(30);
        @include media-breakpoint-down(sm) {
          margin-bottom: rem-calc(20);
        }
      }
    }
  }

  .footer-logos {
    max-width: 450px;
    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  .footer-highlight {
    margin-bottom: rem-calc(30);

    @include media-breakpoint-up(lg) {
      order: 3;
    }
  }
}
