section.paragraph.paragraph-history {
  border-top: 1px solid var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);
  padding: 0;

  .headline-wrapper {
    margin-top: rem-calc(100);
    padding-bottom: rem-calc(50);
    border-bottom: 1px solid var(--border-color-1);
  }

  .history-timeline-element {
    > .text-wrapper {
      padding: rem-calc(32) 0;
    }
    @include media-breakpoint-up(md) {
      .image-wrapper {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          background-color: var(--border-color-1)
        }
      }

      // even elements
      &:nth-child(2n) {
        .image-wrapper::before {
          right: -0.5px;
        }
      }

      // odd elements
      &:nth-child(2n + 1) {
        flex-direction: row-reverse;
        justify-content: start;
        
        .image-wrapper::before {
          left: -0.5px;
        }
      }
      &:first-child {
        .text-wrapper {
          padding-top: rem-calc(50);
        }
      }

      &:last-child {
        .text-wrapper {
          padding-bottom: rem-calc(50);
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: rem-calc(30) 0;
      border-bottom: 1px solid var(--border-color-1);
    }

    &.with-image .image-wrapper {
      padding: rem-calc(50) 0;
    }

    @include media-breakpoint-up(md) {
      &.with-image {
        .text-wrapper {
          padding-top: rem-calc(60);
        }
      }
    }
  }
}
