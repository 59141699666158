section.filter-cloud {
  padding: rem-calc(50) 0;
  .filter {
    background-color: var(--grey-50);
    color: $dark;
    @include body(xs);
    border-radius: rem-calc(12);
    padding: rem-calc(4) rem-calc(8);
    display: inline-block;
    margin-right: rem-calc(15);
    margin-bottom: rem-calc(15);
    cursor: pointer;
    border: rem-calc(1) solid transparent;
    &.active {
      border: rem-calc(1) solid $dark;
    }
  }
}

section.search {
  margin: $paragraph-section-padding;
}

section.filter-text,
section.filter-dialog-baum {
  margin: $paragraph-section-padding;
  p {
    @include body(l);
    display: inline;
  }
  &.filter-dialog-baum {
    p {
      vertical-align: middle;
    }
  }
  .bx--form-item {
    display: inline;
    margin: 0 rem-calc(30) 0 rem-calc(15);
    .bx--select {
      display: inline-block;
    }
  }
  div.filter {
    cursor: pointer;
    display: inline-block;
    background: var(--grey-50);
    border-radius: rem-calc(32);
    padding: rem-calc(4) rem-calc(18);
    margin: 0 rem-calc(15);
    &.active {
      background: var(--grey-10);
      span {
        color: var(--theme-1);
        svg {
          padding: 0 0 rem-calc(5) 0;
          fill: var(--theme-1);
        }
      }
    }
  }
}

.pagination {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    li {
      cursor: pointer;
      a {
        display: table;
        padding: rem-calc(15);
        color: black;
      }
      &.selected {
        border: rem-calc(1) solid var(--theme-1);
        a {
          color: var(--theme-1);
        }
      }
      &.previous,
      &.next {
        min-width: auto;
        padding: 0;
        a {
          color: white;
        }
      }
      &.previous {
        margin-right: auto;
      }
      &.next {
        margin-left: auto;
      }
      &.disabled {
        visibility: hidden;
      }
    }
  }
}

.paragraph.paragraph-extended-teaser-overview {
  article.node-teaser.teaser-person.teaser-person-overview {
    margin-bottom: rem-calc(70);
  }

  article.node-teaser.teaser-educational-resource.teaser-educational-resource-overview {
    flex-direction: column;
    margin: rem-calc(25) 0;
    @include media-breakpoint-up(md) {
      padding-right: rem-calc(50);
    }

    h3 {
      margin-top: rem-calc(30);
    }

    .link-list-wrapper {
      ul {
        padding-left: 0;

        li {
          &::before {
            content: "↓";
            display: inline;
            padding-right: 10px;
            @include body(xs);
          }
        }
      }

      a {
        @include link(text);
      }
    }
  }

  &.type-projekt {
    @include media-breakpoint-up(md) {
      .col-md-custom {
        max-width: 33.333%;
        width: 33.333%;
        flex: 0 0 33.333%;
      }
      .col-md-custom-highlighted {
        max-width: 100%;
        width: 100%;
        padding: 0 25%;
        //margin: 0 25%;
      }
      .small_big {
        .col-16:not(.col-md-5) {
          @include make-col(11);
        }
      }
    }
  }
}

.container-fluid > .row > .col-third {
  padding: 0;
}

@include media-breakpoint-down(md) {
  .col-third {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .col-third {
    width: calc(100% / 2);
  }
}

@include media-breakpoint-up(lg) {
  .col-third {
    width: calc(100% / 3);
  }
}

/* Article Overview */
/* Events, News, Press Releases, Jobs */
section.paragraph.paragraph-extended-teaser-overview.type-news,
section.paragraph.paragraph-extended-teaser-overview.type-veranstaltung,
section.paragraph.paragraph-extended-teaser-overview.type-article {
  .container-fluid > .row {
    flex-flow: row wrap;
  }
}

.teaser-overview-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > * {
    margin-bottom: 0;
  }

  a {
    @include link(small);
  }
}

article.node.node-teaser.teaser-article {
  height: 100%;

  small {
    margin-bottom: rem-calc(16);
  }

  a {
    outline: 1px solid var(--grey-50);
    outline-offset: -0.5px;

    @include theme(light) {
      @include transition(background-color);
    }

    @include theme(dark) {
      &:hover {
        background-color: var(--black);

        * {
          color: var(--white);
        }
      }
    }

    background-color: var(--white);

    &:hover {
      background-color: var(--theme-2);
      * {
        text-decoration: none;
      }
    }
  }

  .image-wrapper {
    height: rem-calc(300);
    overflow: hidden;
  }

  .text-wrapper {
    padding: rem-calc(50);

    @include media-breakpoint-down(md) {
      padding: 6.25%;
    }
  }
}

/* Person overview */
section.paragraph.paragraph-extended-teaser-overview.type-person {
  padding: 0 0;

  .section-header {
    border-top: 1px solid var(--grey-30);
    border-bottom: 1px solid var(--grey-30);

    .headline-wrapper {
      @include media-breakpoint-up(xl) {
        border-right: 1px solid var(--grey-30);
      }

      padding: rem-calc(15) rem-calc(30);
    }
  }

  .section-body {
    .space-wrapper {
      @include media-breakpoint-up(xl) {
        border-right: 1px solid var(--grey-30);
      }
    }

    .teaser-wrapper {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, #{rem-calc(400)}));
      grid-auto-rows: auto;
      gap: rem-calc(30);
      position: relative;
      padding: 0 0 rem-calc(30);

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, minmax(0, #{rem-calc(400)}));
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: minmax(0, #{rem-calc(400)});
      }

      article.teaser-person-overview {
        word-wrap: break-word;
        margin-bottom: 0;

        .teaser-person-image-wrapper {
          position: relative;
          margin-bottom: rem-calc(30);

          img {
            margin-bottom: 0;
          }

          .expand-button {
            position: absolute;
            background-color: var(--theme-1);
            bottom: 0;
            right: 0;
            padding: rem-calc(5);
            display: flex;
            transition: 0.3s background-color;
          }

          &:hover {
            .expand-button {
              background-color: var(--theme-1-active);
            }
          }
        }

        .teaser-person-text-wrapper {
          padding: 0 rem-calc(20);
        }

        .role {
          margin-top: rem-calc(10);

          .small {
            letter-spacing: rem-calc(1.8);
          }
        }

        .contact-data {
          margin-top: rem-calc(15);

          > span {
            display: block;
            @include body(xs);
            margin-bottom: rem-calc(8);

            a {
              @include link;
              display: inline;
            }
          }
        }

        /* Line as ::before element on every 3n+1 element (beginning on 4th element) */
        position: initial;

        &:nth-child(3n + 1):not(:first-child):before {
          width: 100%;
          height: 1px;
          content: "";
          background-color: var(--grey-30);
          position: absolute;
          /* bottom: 0; */
          transform: translateY(-1px);
          left: 0;
        }
      }
    }
  }
}

.teaser-job-offer {
  border-top: 1px solid var(--border-color-1);

  &:last-child {
    border-bottom: 1px solid var(--border-color-1);
  }

  &:hover {
    background-color: var(--theme-2);
  }

  a {
    text-decoration: none;
    display: block;
    padding: rem-calc(25) 0;

    small {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
