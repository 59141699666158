body.fullscreen-menu-open {
  overflow-y: hidden !important;
  //max-height: 100vh;
}

#fullscreen-menu {
  background: var(--white);
  position: fixed;
  width: 100%;
  min-height: calc(100vh - 96px);
  padding-top: 96px;
  right: -110%;
  z-index: 10;
  transition: all 500ms ease;
  overflow-y: scroll;
  height: 100%;
  border-top: 1px solid var(--grey-30);

  &.active {
    right: 0;
  }

  .nav-wrap {
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &,
    .container,
    .row {
      height: 100%;
    }

    nav.main-menu-mobile {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        width: 100%;
        @include header-link;
        padding: rem-calc(15) 0;
        justify-content: center;
      }

      li {
        width: 100%;
        position: relative;
      }

      .level-1,
      .level-2 {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        width: 100%;
      }

      .level-2-wrapper {
        display: none;
        margin-bottom: rem-calc(25);

        &.open {
          display: flex;
        }

        a {
          padding: rem-calc(10) 0;
        }
      }

      .language-switch-wrapper {
        border-top: 1px solid var(--grey-30);
        padding-top: rem-calc(10);
        margin-top: 0;

        > .language-switch {
          min-width: 300px;
        }
      }
    }

    .main-menu-mobile-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: rem-calc(32);

      .social-media {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: center;
        gap: rem-calc(24);

        img {
          width: rem-calc(20);
          height: rem-calc(20);
          opacity: 0.5;
        }

        a:hover img {
          opacity: 1;
        }
      }

      display: flex;
      justify-content: center;
      margin-bottom: rem-calc(64);
      margin-top: rem-calc(32);
    }

    div.often-clicked {
      background: var(--white);
      padding: rem-calc(30) 0 rem-calc(50) 0;
      h2 {
        @include body(s);
        margin-bottom: rem-calc(40);
      }
      nav.often-clicked {
        ul {
          display: flex;
          li {
            margin-right: rem-calc(25);
            margin-bottom: rem-calc(35);
            a {
              @include header-link();
            }
          }
        }
      }
    }
  }
}
