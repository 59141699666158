// Bootstrap Breakpoints.
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-columns: 16;
$grid-gutter-width: 0.875rem;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone -> 576px
  sm: 576px,
  // Medium screen / tablet -> 768px
  md: 768px,
  // Large screen / desktop -> 1024px
  lg: 1024px,
  // Extra large screen / wide desktop -> 1200px
  xl: 1200px,
  xxl: 2000px
);

$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 2000px,
);

main {
  max-width: 2000px;
  margin-left: auto !important;
  margin-right: auto !important;
}

// Theme paddings and margins.
$paragraph-section-padding: rem-calc(30) 0;
$paragraph-section-padding-mobile: rem-calc(20) 0;
$whitespace: rem-calc(20);

// Transitions.
$transition-duration: 0.5s;
$transition-timing-function: ease;
$enable-grid-classes: true;

//@import "rtl";
