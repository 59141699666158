section.paragraph.paragraph-intro {
  padding: 0;
  position: relative;
  border-bottom: 1px solid var(--border-color-1);
  margin-bottom: 0;

  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }

  .headline-wrapper {
    margin-top: rem-calc(40);

    @include media-breakpoint-up(md) {
      position: absolute;
      top: rem-calc(100);
      margin-top: 0;
    }
  }

  .description {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);

    p {
      @include body(m);
    }
  }

  .button-tickets {
    position: absolute;
    right: 10%;
    top: 7.5rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-1);
    color: var(--black);
    text-decoration: none;
    height: 11rem;
    width: 11rem;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    transition: 0.3s background-color;
    gap: rem-calc(10);
    padding: rem-calc(20);

    &:hover {
      background-color: var(--theme-1-active);
    }

    .ticket-link-main {
      @include headline(m);
      margin-bottom: 0;

      @include theme(dark) {
        color: var(--white);
      }
    }

    .ticket-link-sub {
      @include body(xxs);

      @include theme(dark) {
        color: var(--white);
      }
    }
  }

  .button-more {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    bottom: 0;
    transform: rotate(270deg);

    @include media-breakpoint-down(md) {
      right: 0;
    }

    &.button-transparent {
      .label-wrapper {
        background-color: transparent;
      }
    }

    .icon-wrapper,
    .label-wrapper {
      padding: rem-calc(15);
      height: rem-calc(56);
      display: flex;
      align-items: center;
    }

    .icon-wrapper {
      background-color: var(--theme-1);
      width: rem-calc(56);

      .icon {
        width: rem-calc(16);
        height: rem-calc(16);
        transition: 0.3s transform cubic-bezier(0.75, 0, 0.25, 1);

        @include theme(dark) {
          filter: none;
        }
      }
    }

    .label-wrapper {
      @include body(xxs);
      position: absolute;
      left: 0;
      min-width: max-content;
      left: rem-calc(56);
      background-color: var(--white);
      color: var(--black);
    }

    &:hover {
      .icon-wrapper {
        background-color: var(--theme-1-active);

        .icon {
          transform: translateX(-5px);
        }
      }
    }
  }
}
section.paragraph.paragraph-intro.fullpage {
  .mobile {
    display: none;
    @include media-breakpoint-down(lg) {
      display: unset;
    }
  }
  .desktop {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  .button-more {
    right: 0;
    @include media-breakpoint-down(lg) {
      display: flex;
      left: 0;
      right: unset;
    }
    .label-wrapper {
      background-color: unset;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  .upper {
    margin: 0;
    position: relative;
    width: 100%;
    padding: 0;
    max-width: unset;
    img, video {
      object-fit: cover;
      height: calc(100svh - 122px);
      @include media-breakpoint-down(lg) {
        height: calc(100svh - 96px);
      }
    }
    .row {
      max-width: 100%;
    }

    .big {
      padding: rem-calc(70) rem-calc(50) rem-calc(70)
        calc(var(--bxgutter-x) * 0.5);
    }
  }
  .row {
    position: relative;
  }
  .headline-wrapper {
    background-color: var(--theme-2);
    position: absolute;
    bottom: 0;
    top: unset;
    left: 0;
    height: fit-content;
  }

  .lower {
    @include media-breakpoint-down(lg) {
      background-color: var(--theme-2);
      max-width: 100%;
      padding-top: rem-calc(50);
    }
    .text {
      @include media-breakpoint-down(lg) {
        margin-bottom: rem-calc(45);
      }
      .title {
        h3 {
          @include body(m);
          font-weight: 400;
        }
      }
      .description {
        @include media-breakpoint-down(lg) {
          padding: rem-calc(15) 0;
        }

        .heading {
          @include body(m);
          font-weight: 400;
          p {
            display: none;
          }
        }
      }
    }
    a {
      position: relative;
      padding-left: 45px !important;
      @include link(small);
      &:before {
        content: "";
        background-color: var(--black);
        height: 1px;
        width: 40px;
        position: absolute;
        left: 0;
        top: 50%;
      }
    }

    .accordion__button[aria-expanded="true"] {
      .mehr {
        display: none;
      }
    }
    .accordion__button[aria-expanded="false"] {
      .weniger {
        display: none;
      }
    }
  }
}
