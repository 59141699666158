$font-family-base: "Graphik Web", sans-serif;

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik/Graphik-MediumItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik/Graphik-Medium-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik/Graphik-Regular-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("../fonts/graphik/Graphik-Light-Web.woff2") format("woff2"),
    url("../fonts/graphik/Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
/*
@font-face {
  font-family: "Geeza Pro";
  src: url("../fonts/geeza-pro/a0eedb9e027437b7da1d5447c062a185.eot");
  src: url("../fonts/Geeza Pro/a0eedb9e027437b7da1d5447c062a185.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/geeza-pro/a0eedb9e027437b7da1d5447c062a185.woff2")
      format("woff2"),
    url("../fonts/geeza-pro/a0eedb9e027437b7da1d5447c062a185.woff")
      format("woff"),
    url("../fonts/geeza-pro/a0eedb9e027437b7da1d5447c062a185.ttf")
      format("truetype"),
    url("../fonts/geeza-pro/a0eedb9e027437b7da1d5447c062a185.svg#Geeza Pro")
      format("svg");
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}*/


@mixin body($type) {
  font-family: $font-family-base;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: unset;
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  color: var(--body);

  @if $type == xxs {
    font-size: rem-calc(11);
    text-transform: uppercase;
    letter-spacing: rem-calc(2.38);
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(11);
    }
  }
  @if $type == xs {
    font-size: rem-calc(14);
    line-height: 1.2;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(16);
    }
  }
  @if $type == s {
    font-size: rem-calc(16);
    line-height: 1.8;
    margin-bottom: 0.625rem;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(18);
    }
  }
  @if $type == m {
    line-height: 1.8;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(22);
    }
  }
  @if $type == l {
    font-size: rem-calc(24);
    line-height: 1.375;
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(36);
    }
  }
}

@mixin headline($type) {
  font-family: $font-family-base;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: unset;
  font-variant-ligatures: common-ligatures;
  font-variant-alternates: normal;
  color: var(--heading);

  @if $type == s {
    font-size: rem-calc(18);
    line-height: 1.4;
    margin: initial;
    padding: initial;
  }

  @if $type == m {
    font-size: rem-calc(22);
    line-height: 1.4;
    margin-bottom: rem-calc(16);
    background-color: inherit;
    padding: initial;
    text-transform: inherit;
  }

  @if $type == l {
    font-size: rem-calc(24);
    line-height: 1.4;
    letter-spacing: unset;
    margin-bottom: rem-calc(20);
    box-decoration-break: clone;

    @include media-breakpoint-up(lg) {
      font-size: rem-calc(32);
    }
  }

  @if $type == xl {
    font-size: rem-calc(28);
    line-height: 1.4;
    margin-bottom: rem-calc(24);
    @include media-breakpoint-up(lg) {
      font-size: rem-calc(44);
    }
  }
}

@mixin link($type: default) {
  color: var(--black);
  text-decoration: none;
  background-image: linear-gradient(
    var(--theme-1),
    var(--theme-1)
  );
  background-position: left bottom;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease, color 0.3s ease;
  background-size: 100% 0;
  line-height: 1.4;
  margin-left: -3px;
  margin-right: -3px;
  padding: 0 3px;

  &:hover {
    transition-timing-function: cubic-bezier(0.78, 0, 0.54, 0.71);
    background-size: 100% 100%;
    @include theme(dark) {
      color: var(--white);
    }
  }

  @if $type == text {
    background-size: 100% 1px;
    @include body(s);
  }

  @if $type == hero {
    background-size: 100% 2px;
    @include body(m);
  }

  @if $type == underline {
    background-size: 100% 1px;
    @include body(xxs);
  }

  @if $type == small {
    @include body(xxs);
  }
}

/* REM Font Scaling */
html {
  font-size: var(--base-font-size, 1rem) !important;
}
