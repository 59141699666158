/**
 * Teaser Views modes.
 */
article.teaser-news.teaser-news-timeline,
article.teaser-news.teaser-news-timeline-list {
  .tag {
    background-color: var(--grey-20);
    color: var(--grey-10);
    padding: rem-calc(2) rem-calc(6);
    @include body(xs);
    margin-right: rem-calc(10);
    border-radius: rem-calc(12);

    &:before {
      content: "#";
    }
  }

  h3 {
    @include body(m);
    margin-top: rem-calc(20);
  }

  &.teaser-news.teaser-news-timeline-list {
    h3 {
      margin-top: 0;
    }

    img {
      margin: 0;
    }
  }
}

article.node-news {
  @include media-breakpoint-down(md) {
    .text-wrapper {
      margin-top: rem-calc(50);

      @each $key, $max-width in $container-max-widths {
        @include media-breakpoint-up($key) {
          max-width: $max-width;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
