section.paragraph + section.paragraph-teaser-image.horizontal-line,
section.paragraph:not(.paragraph-teaser-image)
  + section.paragraph.paragraph-teaser-image {
  border-top: 1px solid var(--border-color-1);
}

section.paragraph-teaser-image.horizontal-line {
  border-bottom: 1px solid var(--border-color-1);
}

section.paragraph.paragraph-intro + section.paragraph-teaser-image,
section.paragraph.paragraph-teaser-image + section.horizontal-line {
  border-top: none !important;
}

section.paragraph-teaser-image {
  &.theme-blank {
    .img-text .teaser-img-wrap {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -0.5px;
        width: 1px;
        height: 100%;
        background-color: var(--border-color-1);
        z-index: 10;
      }
      @include media-breakpoint-down(lg) {
        &::before {
          display: none;
        }
      }
    }

    .text-img .teaser-img-wrap {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -0.5px;
        width: 1px;
        height: 100%;
        background-color: var(--border-color-1);
        z-index: 10;
      }
      @include media-breakpoint-down(lg) {
        &::before {
          display: none;
        }
      }
    }
  }

  .row.text-img {
    justify-content: space-between;
  }
}

.img-wrapper {
  position: relative;
  overflow: hidden;

  .caption ~ .credit {
    margin-left: rem-calc(8);
  }
}

.img-wrap,
.img-wrapper,
.media-image {
  //padding: rem-calc(50) 0 ;
  //display: flex !important;
  align-items: center;
  //padding: rem-calc(50) 0;
  line-height: 0;

  .img-caption-wrap {
    position: relative;
    line-height: 1.2;
  }

  .slider-wrapper {
    width: 100%;

    .media-image {
      padding: 0 !important;
    }

    .controls {
      position: relative;
    }
  }

  .caption-wrap {
    padding: rem-calc(8) rem-calc(16);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    transition-duration: 0.3s;
    transform: translateY(-100%);
    margin: 0;
    top: -1%;
    width: 100%;
    line-height: 1.2;

    span {
      @include body(xs);
      font-size: 80% !important;
      color: var(--white);
      @include theme(dark) {
        color: var(--black);
      }
    }
  }
  &:hover {
    .caption-wrap {
      transform: translateY(0);
    }
  }
  img {
    object-fit: contain;
  }
}

.img-wrap {
  position: relative;
}

.teaser-img-wrap {
  padding: rem-calc(96) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &,
  & > * {
    position: relative;
  }

  .media-image {
    padding: 0 !important;
  }
}

section.paragraph-teaser-image {
  padding: 0;
  max-width: unset;
  .text-wrap {
    padding: rem-calc(50) rem-calc(50) rem-calc(50)
      calc($grid-gutter-width * 0.5);
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      max-width: unset;
      padding: initial;
      padding-bottom: rem-calc(32);

      .text {
        margin-top: 0;
      }
    }
  }

  .img-wrap {
    padding: 0;
  }

  .text {
    margin: rem-calc(40) 0 rem-calc(30) 0;
    p {
      @include body(s);
    }
  }
}
section.paragraph-teaser-image.theme-blank {
  .img-wrap,
  .media-image {
    padding: rem-calc(50) 0;
  }
}
section.paragraph-teaser-image.theme-2 {
  background: var(--theme-2);
}
section.paragraph-teaser-image.theme-1 {
  background: var(--theme-3);
  color: var(--white);
  .row {
    .text-wrap {
      h3 {
        color: var(--white);
      }
      .text {
        p {
          color: var(--white);
        }
      }
    }
  }
}

.slick-slide {
  > * {
    line-height: 0;
  }
}

.slick-track {
  line-height: 0;
}
