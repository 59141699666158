.paragraph.webform-paragraph {
  &.theme-1 {
    background-color: var(--theme-3);

    p,
    div,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label {
      color: var(--white) !important;
    }

    a {
      @include link(s);
      color: var(--white);

      &:hover {
        color: var(--black);
      }
    }
  }

  .error-message {
    @include body(xxs);
    margin-bottom: rem-calc(5);
  }

  .form-group:not(.form-check) label {
    @include body(xxs);
    margin-bottom: rem-calc(5);
  }

  .form-group.form-check label {
    @include body(xs);
  }

  textarea,
  select,
  input {
    border-radius: 0;
  }

  button {
    @include button;
  }
}
