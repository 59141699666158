section.paragraph.paragraph-akkordion-text,
.paragraph-audio {
  margin-bottom: rem-calc(100);

  > .container > .row > .col-16 {
    padding: 0;
  }

  .react-reveal {
    border-top: rem-calc(1) solid var(--border-color-1);

    &:last-child {
      border-bottom: rem-calc(1) solid var(--border-color-1);
    }
  }

  .accordion__item {

    .container {
      margin-bottom: 0;
    }

    .accordion__heading {
      @include body(s);
      margin-bottom: 0;
      cursor: pointer;
    }

    .accordion__button {
      outline: none;
      &:focus,
      &:focus-visible {
        outline: rem-calc(1) solid;
      }

      .arrow-icon {
        max-width: 20px;
        min-width: 20px;

        @include theme(dark) {
          filter: invert(1);
        }
      }

      .accordion-button-inner {
        display: flex;
        justify-content: space-between;
        padding: rem-calc(25) 0;
      }
    }
    .accordion__panel {
      background-color: var(--theme-2);
      padding: rem-calc(20) 0 rem-calc(45) 0;
    }
  }
}
section.paragraph.paragraph-akkordion-text {
  padding: 0;

  .accordion__item {
    .accordion__heading,
    .accordion__panel {
      &:focus {
        background: var(--theme-2);
      }
      .accordion__panel {
        transition: max-height 1000ms ease-in-out, 750ms opacity;
      }
    }

    .accordion__heading {
      .accordion__button {
        display: flex;
        justify-content: space-between;
      }

      .accordion__button[aria-expanded="true"] {
        img {
          transform: rotate(180deg);
        }
        background-color: var(--theme-2);
      }

      img {
        width: unset;
        max-width: 20px;
        transition: 250ms transform cubic-bezier(0, 0.33, 0.57, 0.91);
      }
    }

    .accordion__heading > .accordion__button {
      outline: none;
    }
  }
}
