// CSS Theme Variables
// Theme Light (default)
:root,
html[data-theme="light"] {
  --theme-1: #fff266;
  --theme-1-active: #ffe900;
  --theme-2: #dfa79d;
  --theme-3: #387a87;
  --white: #ffffff;
  --grey-10: #f1f1f1;
  --grey-20: #e9ecef;
  --grey-30: #dee2e6;
  --grey-40: #ced4da;
  --grey-50: #cccccc;
  --grey-60: #d8d8d8;
  --grey-70: #495057;
  --grey-80: #343a40;
  --grey-90: #1a1a1a;
  --black: #161616;
  --body: var(--black);
  --body-bg: #ffffff;
  --heading: var(--black);
  --heading-with-bg: var(--black);
  --heading-bg: #fee0cc;
  --border-color-1: var(--grey-40);
  --border-color-2: var(--grey-60);

  --button-color: #000;
  --button-bg: var(--theme-1);
}

// Theme High Contrast
html[data-theme="dark"] {
  --theme-1: #fff266;
  --theme-1-active: #ffe900;
  --theme-2: #000000;
  --theme-3: #387a87;
  --white: #000000;
  --grey-10: #1f1f1f;
  --grey-20: #2e2e2e;
  --grey-30: #393939;
  --grey-40: #414141;
  --grey-50: #495057;
  --grey-60: #676767;
  --grey-70: #767676;
  --grey-80: #8e8e8e;
  --grey-90: #ffffff;
  --black: #ffffff;

  --body: var(--black);
  --body-bg: var(--white);

  --heading: var(--black);
  --heading-with-bg: var(--black);
  --heading-bg: var(--grey-40);

  --border-color-1: var(--grey-50);
  --border-color-2: var(--grey-50);

  --button-color: #000;
  --button-bg: var(--theme-1);
}
