.icon {
  display: inline-flex;
  width: rem-calc(24);
  height: rem-calc(24);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include theme("dark") {
    filter: invert(1);
  }

  &.icon-simple-speech {
    background-image: url("/icon-simple-speech.svg");
  }

  &.icon-sign-language {
    background-image: url("/icon-sign-language.svg");
  }

  &.icon-contrast {
    background-image: url("/icon-contrast.svg");
  }
  
  &.icon-moon {
    background-image: url("/icon-moon.svg");
  }
  
  &.icon-sun {
    background-image: url("/icon-sun.svg");
  }

  &.icon-font-size {
    background-image: url("/icon-font-size.svg");
  }

  &.icon-arrow-right {
    background-image: url("/icon-arrow-right.svg");
  }

  &.icon-arrow-left {
    background-image: url("/icon-arrow-left.svg");
  }

  &.icon-download {
    background-image: url("/icon-arrow-down.svg");
  }

  &.icon-arrow-up {
    background-image: url("/icon-arrow-up.svg");
  }

  &.icon-arrow-down {
    background-image: url("/icon-arrow-down.svg");
  }

  &.icon-plus {
    background-image: url("/icon-plus.svg");
  }

  &.icon-cross {
    background-image: url("/icon-cross.svg");
  }

  &.icon-search {
    background-image: url("/icon-search.svg");
  }
}
