/* Basic Typography Styling */
h1,
.headline-xl {
  @include headline(xl);
  margin-bottom: rem-calc(24);
}

h2,
.headline-l {
  @include headline(l);
  margin-bottom: rem-calc(16);
}

h3,
.headline-m {
  @include headline(m);
  margin-bottom: rem-calc(12);
}

h4,
.headline-s {
  @include headline(s);
  margin-bottom: rem-calc(8);
}

h1,
h2,
h3,
h4,
.headline-xl,
.headline-l,
.headline-m,
.headline-s {
  &.headline-with-bg {
    text-transform: uppercase;
    color: var(--heading-with-bg) !important;
    background-color: var(--heading-bg);
    padding: 0 0.15em;
    display: inline;

    + * {
      margin-top: rem-calc(24);
    }
  }
}

body {
  @include body(s);
}

small,
.small {
  @include body(xxs);
  display: inherit;
}

.big {
  @include body(m);

  a:not(.btn) {
    @include link;
  }

  ul {
    li:before {
      content: "–";
    }
  }
}

.text {
  @include body(s);

  a {
    @include link(text);
  }

  p,
  ul,
  ol {
    @include body(s);
  }

  ul {
    list-style: none;

    li {
      padding-left: rem-calc(25);
      padding-bottom: rem-calc(15);
      position: relative;

      &::before {
        content: "—";
        position: absolute;
        left: 0;
      }
    }
  }

  ol {
    list-style: decimal;
    margin-left: rem-calc(-5);

    li {
      padding-left: rem-calc(5);
      padding-bottom: rem-calc(15);
    }
  }

  h3 {
    margin-top: rem-calc(24);
  }
}

b,
strong {
  font-weight: 500;
}
